<template>
  <div>
    <PagesPath :pathTitle1="'درباره ما'" :pathTo1="'about-us'"></PagesPath>
    <section id="aboutUsPage" class="p-3 bg-white box-shaddow20 container mt-4 mb-5 px-5">
      <h5 class="text-color-444 my-4 font-weight-bold">درباره ی آریا</h5>
      <p class="line-height2 text-color-666 fontsize16 text-justify">
        در تمامی این سالها با هدف ایجاد خدمت رسانی در خریدی آسان و مطمئن به
        همکاران محترم در کنار شما بوده ایم و همه تلاشمان عرضه کالایی با کیفیت،و
        قیمت مناسب که شایسته ایران و ایرانی باشد را داشته ایم و خواهیم داشت.امید
        داریم تا با حمایت شما خدمت رسانی بهتری داشته باشیم تا مسیر را برای
        آیندگان هموار تر سازیم
      </p>
      <p class="line-height2 text-color-666 fontsize16 text-justify">
        فروشگاه جانبی آریا گستر ، ضمانت اصالت کالای شما را به عهده دارد. شما می
        توانید با خیال راحت از قیمت و کیفیت آن، سفارش خود را ثبت کرده و محصول را
        دریافت کنید. همچنین ضمانت اصل بودن کالا، خیال شما را درمورد صحت اورجینال
        بودن کالا آسوده میکند.
      </p>

      <h5 class="text-color-444 my-4 font-weight-bold">
        12 سال افتخار خدمت رسانی در زمینه لوازم جانبی موبایل
      </h5>

      <p class="line-height2 text-color-666 fontsize16 text-justify">
        سال 1390 با نام دلفین طلایی در شهر مشهد مقدس با فروشگاهی به مساحت 30 متر
        با هدف ایجاد بستری مفید و حل مشکلات موجود در بازار در عرضه خدمات لوازم
        جانبی از کشور چین به کشور عزیزمان ایران شروع به کار کردیم تا بتوانیم
        کالایی با کیفیت، با اصالت و تضمین شده را به بازار عرضه کنیم.
      </p>
      <p class="line-height2 text-color-666 fontsize16 text-justify">
        در سال 1399 به لطف خداوند و دلگرمی مشتریان، شعبه استان گلستان در شهر
        گنبد با مساحت 24 متر افتتاح گردید، در طی این سالها با تمام فراز و نشیب
        ها فراوان با ایمان به مسیر پیش رو به حرکت خود ادامه دادیم تا به امروز که
        پر قدرت از همیشه در کنار شما سروران ایستاده ایم و این شروع مسیر ماست در
        کنار شما....
      </p>
      <div>
        <ul>
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="#ff762d"
              style="transform: ; msfilter: "
            >
              <path
                d="m10.933 13.519-2.226-2.226-1.414 1.414 3.774 3.774 5.702-6.84-1.538-1.282z"
              ></path>
              <path
                d="M19 3H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM5 19V5h14l.002 14H5z"
              ></path>
            </svg>
            افتتاح دو فروشگاه فعال در شهر مقدس مشهد و گرگان
          </li>
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="#ff762d"
              style="transform: ; msfilter: "
            >
              <path
                d="m10.933 13.519-2.226-2.226-1.414 1.414 3.774 3.774 5.702-6.84-1.538-1.282z"
              ></path>
              <path
                d="M19 3H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM5 19V5h14l.002 14H5z"
              ></path>
            </svg>

            ثبت گارانتی سلامت فیزیکی کالا با نام آریا گستر
          </li>
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="#ff762d"
              style="transform: ; msfilter: "
            >
              <path
                d="m10.933 13.519-2.226-2.226-1.414 1.414 3.774 3.774 5.702-6.84-1.538-1.282z"
              ></path>
              <path
                d="M19 3H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM5 19V5h14l.002 14H5z"
              ></path>
            </svg>

            ایجاد اشتغال برای بالای 50 نفر
          </li>
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="#ff762d"
              style="transform: ; msfilter: "
            >
              <path
                d="m10.933 13.519-2.226-2.226-1.414 1.414 3.774 3.774 5.702-6.84-1.538-1.282z"
              ></path>
              <path
                d="M19 3H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM5 19V5h14l.002 14H5z"
              ></path>
            </svg>

            قانونی بودن تمامی کالاهای وارداتی
          </li>
        </ul>
      </div>
    </section>

    <Footer></Footer>
  </div>
</template>

<script>
import PagesPath from "@/parts/Front/components/PagesPath";
export default {
  name: "AboutUs",
  components: {
    PagesPath,
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style scoped>
#aboutUsPage ul li {
  list-style-type: none;
  margin-top: 0.5rem;
}
</style>
